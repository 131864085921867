import React from "react";

import styles from "./employee-preview-list.module.css";
import EmployeePreview from "./employee-preview";

function EmployeePreviewGrid(props) {
  return (
    <div className={styles.root}>
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.length > 0 &&
          props.nodes.map((node) => (
            <li key={node.id}>
              <EmployeePreview {...node} isInList />
            </li>
          ))}
      </ul>
    </div>
  );
}

EmployeePreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default EmployeePreviewGrid;
