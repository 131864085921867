import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, formatPhoneNumber} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './employee-preview.module.css'
import {linkStyle} from './typography.module.css'

function EmployeePreview (props) {
  return (
      <Link
        className={props.isInList ? styles.inList : styles.inGrid}
        to={props.link}
      >
        
        <div className={styles.container}>
          {props.image && props.image.asset && (
            <img
              src={imageUrlFor(buildImageObj(props.image))
                .width(300)
                .height(300)
                .auto('format')
                .url()}
              alt={props.image.alt}
            />
          )}
          <div className={styles.overlay}>
            <div className={styles.text}>
              <h3>{props.name}</h3>
              <ul className={styles.bulletList}>
                <li>{props.jobTitle}</li>
                <li>{formatPhoneNumber(props.phoneNumber)}</li>
                <li>
                  <a className={linkStyle} href={`mailto:${props.email}`}>{props.email}</a>
                </li>
              </ul>
              
            </div>
        </div>
        </div>
        <div className={styles.text}>
            <h3>{props.name}</h3>
          </div>
      </Link>
  )
}

export default EmployeePreview
