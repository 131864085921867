import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import PortableText from '../components/portableText'
import {toPlainText} from '../lib/helpers'
import PartnerPreviewList from '../components/partner-preview-list'
import EmployeePreviewList from '../components/employee-preview-list'
import {responsiveTitle1} from '../components/typography.module.css'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query AboutPageQuery {
    about: sanityAbout(_id: { regex: "/(drafts.|)about/" }) {
      title
      excerpt
      _rawBody(resolveReferences: {maxDepth: 5})
    }
    partners: allSanityPartner {
      edges {
        node {
          id
          partnerName
          logo {
            ...SanityImage
            alt
          }
          link
        }
      }
    }
    employees: allSanityEmployee {
      edges {
        node {
          id
          image {
            ...SanityImage
            alt
          }
          name
          slug {
            current
          }
          phoneNumber
          email
          jobTitle
        }
      }
    }
  }
`

const AboutPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const about = (data || {}).about
  const authorNodes = (data || {}).employees
    ? mapEdgesToNodes(data.employees)
    : []
  const partnerNodes = (data || {}).partners
    ? mapEdgesToNodes(data.partners)
    : []  

  if (!about) {
    throw new Error(
      'Missing "About". Open the studio at http://localhost:3333 and add some content to "About" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO
        title={about.title}
        description={about.excerpt}
      />
      <Container>
        <h1 className={responsiveTitle1}>{about.title}</h1>
        {about._rawBody && <PortableText blocks={about._rawBody} />}
        <h2>Møt våre medarbeidere</h2>
      </Container>
      {authorNodes && (
          <EmployeePreviewList
            title='Medarbeidere'
            nodes={authorNodes}
          />
        )}
        <Container>
        <h2>Våre tjenestepartnere</h2>
        {partnerNodes && (
          <PartnerPreviewList
            title='Tjenestepartnere'
            nodes={partnerNodes}
          />
        )}
        </Container>
    </Layout>
  )
}

export default AboutPage
