import React from 'react'

import styles from './partner-preview-list.module.css'
import PartnerPreview from './partner-preview'

function PartnerPreviewGrid (props) {
  return (
    <div className={styles.root}>
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id}>
              <PartnerPreview {...node} isInList />
            </li>
          ))}
      </ul>
    </div>
  )
}

PartnerPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: ''
}

export default PartnerPreviewGrid
