import {Link} from 'gatsby'
import React from 'react'
import {buildImageObj, cn, getPartnerUrl} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './partner-preview.module.css'
import {responsiveTitle3} from './typography.module.css'

function PartnerPreview (props) {
  return (
    <Link
      className={props.isInList ? styles.inList : styles.inGrid}
      to={props.link}
    >
      <div>
        {props.logo && props.logo.asset && (
          <img
            src={imageUrlFor(buildImageObj(props.logo))
              .width(80)
              .auto('format')
              .url()}
            alt={props.logo.alt}
          />
        )}
      </div>
      <div className={styles.text}>
        <h3 className={cn(responsiveTitle3, styles.title)}>{props.partnerName}</h3>
      </div>
    </Link>
  )
}

export default PartnerPreview
